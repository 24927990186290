import {
  puur_tile,
  afas_tile,
  aws_tile,
  spendcloud_tile,
  topdesk_tile,
  kpn_tile,
  xpert_tile,
  gadmin_tile,
  qurentis_tile,
  zkde_tile,
  iam_tile,
  portero_tile,
  ziezo_tile,
  venvn_tile,
  esculine_tile,
  stube_tile,
  cb_tile,
  mm_tile,
} from "../Apps/ImageImport";

const business_controller_access = JSON.parse(process.env.REACT_APP_BC);
const or_access = JSON.parse(process.env.REACT_APP_SOR);
const esculine_access = JSON.parse(process.env.REACT_APP_ESCULINE);
const ziezo_access = JSON.parse(process.env.REACT_APP_ZIEZO);
const teams_stichting_access = JSON.parse(
  process.env.REACT_APP_ALL_TC_TEAM_STICHTING
);
const topdesk_access = JSON.parse(process.env.REACT_APP_TOPDESK);
const broadsoft_access = JSON.parse(process.env.REACT_APP_BROADSOFT);
const appstream_access = JSON.parse(process.env.REACT_APP_APPSTREAM);
const xpert_suite_access = JSON.parse(process.env.REACT_APP_XPERTSUITE);
const ztb_access = JSON.parse(process.env.REACT_APP_ZTB);
const thuiscoaching_teammanagement = JSON.parse(
  process.env.REACT_APP_ALL_TC_TEAM_MANAGEMENT
);
const thuiscoaching_bedrijfsvoering = JSON.parse(
  process.env.REACT_APP_ALL_TC_TEAM_BEDRIJFSVOERING
);
// const wissel_tool = JSON.parse(process.env.REACT_APP_WISSELTOOL);
const mailmerge_access = JSON.parse(process.env.REACT_APP_MAILMERGE);

export const additionalApps = [
  {
    name: "PUUR Administratie",
    url: "https://zuidzorg.ecare.nl",
    image: puur_tile,
    condition: (userData, userFunction, department) =>
      business_controller_access.includes(userFunction) ||
      or_access.includes(userFunction) ||
      teams_stichting_access.includes(userFunction) ||
      teams_stichting_access.includes(department),
  },
  {
    name: "AppStream",
    url: "https://accounts.google.com/o/saml2/initsso?idpid=C01posjoi&spid=322081757974&forceauthn=false",
    image: aws_tile,
    condition: (userData, userFunction, department) =>
      thuiscoaching_teammanagement.includes(department) ||
      thuiscoaching_bedrijfsvoering.includes(department) ||
      appstream_access.includes(department) ||
      appstream_access.includes(userFunction) ||
      teams_stichting_access.includes(department) ||
      teams_stichting_access.includes(userFunction),
  },
  {
    name: "ZieZo",
    url: "https://ziezo.zuidzorg.nl",
    image: ziezo_tile,
    condition: (userData, userFunction, department, primaryEmail) =>
      or_access.includes(userFunction) || ziezo_access.includes(primaryEmail),
  },
  {
    name: "ZKDE",
    url: "https://ztb.zkde.nl/login.php",
    image: zkde_tile,
    condition: (userData, userFunction, department) =>
      ztb_access.includes(department),
  },
  {
    name: "Portero",
    url: "https://aanvraag.ciz.nl/isa-zorgaanmelder-web/login/auth",
    image: portero_tile,
    condition: (userData, userFunction, department) =>
      ztb_access.includes(department),
  },
  {
    name: "V&VN",
    url: "https://mijn.venvn.nl/SignIn?returnUrl=/",
    image: venvn_tile,
    condition: (userData, userFunction, department) =>
      teams_stichting_access.includes(department) ||
      teams_stichting_access.includes(userFunction),
  },
  {
    name: "EscuLine",
    url: "https://platform.esculine.nl/zuidzorg",
    image: esculine_tile,
    condition: (userData, userFunction, department, primaryEmail) =>
      teams_stichting_access.includes(department) ||
      teams_stichting_access.includes(userFunction) ||
      esculine_access.includes(primaryEmail),
  },
  {
    name: "Leerhuis ZuidZorg",
    url: "https://zuidzorg.studytube.nl/discover",
    image: stube_tile,
    condition: (userData, userFunction, department) =>
      teams_stichting_access.includes(department) ||
      teams_stichting_access.includes(userFunction),
  },
  {
    name: "Spendcloud",
    url: "https://zuidzorg.spend.cloud/",
    image: spendcloud_tile,
    condition: (userData, userFunction, department) =>
      thuiscoaching_teammanagement.includes(department) ||
      thuiscoaching_bedrijfsvoering.includes(department) ||
      teams_stichting_access.includes(department) ||
      teams_stichting_access.includes(userFunction),
  },
  {
    name: "Qurentis",
    url: "https://qworkx1.qurentis.nl/guacamole/#/",
    image: qurentis_tile,
    condition: (userData, userFunction, department) =>
      thuiscoaching_teammanagement.includes(department) ||
      thuiscoaching_bedrijfsvoering.includes(department),
  },
  {
    name: "Topdesk",
    url: "https://zuidzorg.topdesk.net/tas/secure/login/saml",
    image: topdesk_tile,
    condition: (userData, userFunction, department) =>
      topdesk_access.includes(department),
  },
  {
    name: "Google Admin",
    url: "https://admin.google.com",
    image: gadmin_tile,
    condition: (userData, userFunction, department) =>
      userData.isDelegatedAdmin || userData.isAdmin,
  },
  {
    name: "IAM Adapta",
    url: "https://zuidzorg.iam-adapta.nl",
    image: iam_tile,
    condition: (userData, userFunction, department) =>
      userData.isDelegatedAdmin || userData.isAdmin,
  },
  {
    name: "Xpert Suite",
    url: "https://apps02.xpertsuite.nl/",
    image: xpert_tile,
    condition: (userData, userFunction, department) =>
      xpert_suite_access.includes(userFunction) ||
      xpert_suite_access.includes(department),
  },
  {
    name: "Broadsoft Callcenter",
    url: "https://webapps.kpneen.nl/callcenter/",
    image: kpn_tile,
    condition: (userData, userFunction, department) =>
      broadsoft_access.includes(department),
  },
  {
    name: "AFAS Insite",
    url: "https://login.afasonline.com",
    image: afas_tile,
    condition: (userData, userFunction, department) =>
      teams_stichting_access.includes(department) ||
      teams_stichting_access.includes(userFunction) ||
      or_access.includes(userFunction),
  },
  {
    name: "Mail Merge",
    url: "https://mailmerge.zuidzorg.nl",
    image: mm_tile,
    condition: (userData, userFunction, department, primaryEmail) =>
      mailmerge_access.includes(primaryEmail),
  },
];
