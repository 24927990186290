import React, { useState, useEffect } from "react";
import "./home.css";

const Tools = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [backupCodes, setBackupCodes] = useState([]);
  const [userDetails, setUserDetails] = useState(null);
  const [stringifiedUserDetails, setStringifiedUserDetails] = useState("");
  const [stringifiedCodes, setStringifiedCodes] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [temporaryPassword, setTemporaryPassword] = useState(null);

  useEffect(() => {
    if (userDetails) {
      const stringifiedData = JSON.stringify(userDetails, null, 2);
      setStringifiedUserDetails(stringifiedData);
      console.log("User Details: ", stringifiedData);
    }
  }, [userDetails]);

  useEffect(() => {
    if (backupCodes.length > 0) {
      const stringifiedCodes = JSON.stringify(backupCodes, null, 2);
      setStringifiedCodes(stringifiedCodes);
      console.log("Backup Codes: ", stringifiedCodes);
    }
  }, [backupCodes]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setUserDetails(null);
    setStringifiedUserDetails("");
    setError(null);
  };

  const handleGetBackupCodes = async () => {
    setIsLoading(true);
    setError(null);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userKey: searchQuery }),
    };

    try {
      const response = await fetch(
        "https://us-central1-webportaal.cloudfunctions.net/getBackupCode_V2",
        requestOptions
      );
      if (!response.ok) {
        throw new Error(
          `Network response was not ok (status: ${response.status})`
        );
      }

      const data = await response.json();
      console.log("Backup Code Response:", data);

      if (Array.isArray(data)) {
        console.log("Backup Codes Array:", data);
        setBackupCodes(data);
      } else {
        console.error("Unexpected response structure:", data);
        setError("Unexpected response structure");
      }
    } catch (error) {
      console.error("Failed to fetch backup codes:", error);
      setError(`Failed to fetch backup codes: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const renderBackupCodes = () => {
    if (backupCodes.length === 0) {
      return (
        <ul className="backup-codes-list">
          {[...Array(10)].map((_, index) => (
            <li key={index} className="backup-code-placeholder"></li>
          ))}
        </ul>
      );
    }
    return (
      <>
        <ul className="backup-codes-list">
          {backupCodes.map((code, index) => (
            <li key={index}>{code}</li>
          ))}
        </ul>
        <div className="red-text">
          Let op: Elke back-upcode kan slechts één keer gebruikt worden.
        </div>
      </>
    );
  };

  const renderTemporaryPassword = () => {
    if (!temporaryPassword) {
      return (
        <ul className="temporary-password-list">
          {[...Array(1)].map((_, index) => (
            <li key={index} className="temporary-password-placeholder"></li> // Lege placeholder voor tijdelijk wachtwoord
          ))}
        </ul>
      );
    }

    return (
      <ul className="temporary-password-list">
        <li>{temporaryPassword}</li>
      </ul>
    );
  };

  const handleSearchSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setError(null); // Reset previous errors
    setBackupCodes([]); // Reset the backup codes

    const endpoint =
      "https://us-central1-webportaal.cloudfunctions.net/getUser_V2";
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userKey: searchQuery, projection: "full" }),
    };

    try {
      const response = await fetch(endpoint, requestOptions);
      if (!response.ok) {
        throw new Error(
          `Network response was not ok (status: ${response.status})`
        );
      }

      const result = await response.json();
      console.log("API Response: ", result);

      if (result.user) {
        setUserDetails(result.user);
      } else if (Array.isArray(result)) {
        setUserDetails(result);
      } else {
        setUserDetails(result);
      }
    } catch (error) {
      console.error("Het ophalen van gebruikersgegevens is mislukt: ", error);
      setError(
        // `Het ophalen van gebruikersgegevens is mislukt:  ${error.message}`
        `Het ophalen van de gebruikersgegevens is mislukt: controleer het emailadres.`
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleResetPassword = async () => {
    console.log(userDetails);
    if (!userDetails || !userDetails.id) {
      setError("Geen gebruiker geselecteerd.");
      return;
    }

    setIsLoading(true);
    setError(null); // Reset errors

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userKey: userDetails.id }),
    };

    try {
      const response = await fetch(
        "https://us-central1-webportaal.cloudfunctions.net/resetPassword_V2", // Het endpoint van de reset wachtwoord functie
        requestOptions
      );
      if (!response.ok) {
        throw new Error(
          `Network response was not ok (status: ${response.status})`
        );
      }

      const data = await response.json();
      console.log("Reset Password Response:", data);

      if (data.temporaryPassword) {
        setTemporaryPassword(data.temporaryPassword); // Zet tijdelijk wachtwoord in state
      } else {
        throw new Error(
          "Er is iets misgegaan bij het resetten van het wachtwoord."
        );
      }
    } catch (error) {
      console.error("Fout bij het resetten van het wachtwoord:", error);
      setError(`Fout bij het resetten van het wachtwoord: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  var salarisnummer = " - ";
  if (
    userDetails &&
    userDetails.externalIds &&
    userDetails.externalIds.length > 0
  ) {
    salarisnummer = userDetails.externalIds[0].value;
  }

  return (
    <>
      <hr className="border" />
      <div className="content">
        <h2 className="pagename">MFA Backup codes</h2>
        <div className="search-container">
          <form onSubmit={handleSearchSubmit}>
            <input
              type="text"
              placeholder="ZuidZorg e-mailadres invullen"
              value={searchQuery}
              onChange={handleSearchChange}
              className="search-box"
            />
            <div style={{ position: "relative", display: "inline-block" }}>
              <button
                type="submit"
                className="search-button"
                disabled={isLoading}
              >
                Zoeken
              </button>
              {isLoading && (
                <span
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "100%", // Direct rechts van de knop
                    transform: "translateY(-50%)",
                    marginLeft: "2px", // Ruimte tussen knop en spinner
                    marginTop: "4px", // Ruimte tussen knop en spinner
                  }}
                >
                  <div
                    style={{
                      border: "3px solid #013e74",
                      borderRadius: "50%",
                      borderTop: "4px solid #afca09",
                      width: "20px",
                      height: "20px",
                      animation: "spin 0.9s linear infinite",
                    }}
                  ></div>
                </span>
              )}
            </div>
          </form>
          {error && <p className="error">{error}</p>}
          {userDetails && (
            <div className="user-details">
              <div>Naam: {userDetails.name.fullName}</div>
              <div>Salarisnummer: {salarisnummer}</div>
              <div>
                Google MFA:
                <span
                  style={{
                    color: userDetails.isEnrolledIn2Sv ? "#69be28" : "crimson",
                    fontWeight: "normal",
                  }}
                >
                  {userDetails.isEnrolledIn2Sv ? " Aan" : " Uit"}
                </span>
              </div>
              <div>
                MFA afgedwongen:
                <span
                  style={{
                    color: userDetails.isEnforcedIn2Sv ? "#69be28" : "crimson",
                    fontWeight: "normal",
                  }}
                >
                  {userDetails.isEnforcedIn2Sv ? " Aan" : " Uit"}
                </span>
              </div>

              <div className="columns">
                <div className="column">
                  <button
                    type="button"
                    className="code-button"
                    disabled={isLoading}
                    onClick={handleGetBackupCodes}
                  >
                    Codes genereren
                  </button>
                  {renderBackupCodes()}
                </div>
                {/* <div className="column">
                  <button
                    type="button"
                    className="code-button"
                    disabled={isLoading}
                    onClick={handleResetPassword}
                  >
                    Reset Wachtwoord
                  </button>
                  {renderTemporaryPassword()}
                </div> */}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Tools;
